import React from 'react'
import Link from 'gatsby-link'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import {Form, Field} from 'react-final-form'
import 'whatwg-fetch'
import { graphql } from 'gatsby'

class Team extends React.Component {
    constructor() {
        super();
        this.state = {
            submitted: false
        }
    }

    header() {
        return <div className="medium-12 medium-offset-0 small-10 small-offset-1 cell">
            <h3>
                About Us
            </h3>
        </div>
    }

    render() {
        const siteTitle = get(this, 'props.data.site.siteMetadata.title')

        return (
            <div>
                <Helmet title={'About / ' + siteTitle}/>

                <div className="pagehead">
                    <div className="pagehead__wrap black small"/>
                </div>

                <div className="callout white">
                    <div className="grid-container">
                        <div className="grid-x">
                            {this.header()}
                            <div className="small-12">
                                <p>Our main office is located in Slovakia, Bratislava.</p>

                                <table style={{fontSize:'0.9em', fontWeight: 'bold'}}>
                                    <tr>
                                        <td valign="top">Address:</td>
                                        <td>
                                            Slovakia, Bratislava<br/>
                                            811 02, Tallerova 4<br/>
                                            Simway s.r.o.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Phone:</td>
                                        <td>
                                            <a href="tel:+421944028184">+421 944 028 184</a><br/>
                                            <a href="tel:+421904616603">+421 904 616 603</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">E-mail:</td>
                                        <td>
                                            <a href="mailto:info@mobelop.com">info@mobelop.com</a>
                                        </td>
                                    </tr>
                                </table>

                                We have a distributed team and our team members are primarily located in Ukraine.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Team

export const pageQuery = graphql`
    query AboutQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`
